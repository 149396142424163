import type { IContextProvider } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
import type { IDragAndDropManager } from '@pixcap/ui-core/models/widgets/dragAndDropManager.interface';
import type { IShortcutManager } from '@pixcap/ui-core/models/widgets/shortcut.interface';
import type { IWindowEventsManager } from '@pixcap/ui-core/models/widgets/windowEventsManager.interface';
import { ContextProvider } from '@pixcap/ui-core/widgets/ContextProvider';
import { DragAndDropManager } from '@pixcap/ui-core/widgets/DragAndDropManager';

export interface IPixcapWidgets {
	windowEventsManager: IWindowEventsManager;
	dragAndDropManager: IDragAndDropManager;
	shortcutManager: IShortcutManager;
	contextProvider: IContextProvider;
}
export class PixcapWidgets implements IPixcapWidgets {
	dragAndDropManager: IDragAndDropManager = null;
	contextProvider: IContextProvider = null;
	windowEventsManager: IWindowEventsManager = null;
	shortcutManager: IShortcutManager = null;

	constructor() {
		this.dragAndDropManager = new DragAndDropManager();
		this.contextProvider = new ContextProvider();
	}
}
