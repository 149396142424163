export const safePromiseExecution = <T>(promise: Promise<T>) => promise.then((data) => [undefined, data]).catch((err) => [err, undefined]);

export async function delay(milliseconds = 0, returnValue) {
	return new Promise((done) => setTimeout(() => done(returnValue), milliseconds));
}

export async function isResolved(promise) {
	return await Promise.race([
		delay(0, false),
		promise.then(
			() => true,
			() => false
		),
	]);
}

export async function isRejected(promise) {
	return await Promise.race([
		delay(0, false),
		promise.then(
			() => false,
			() => true
		),
	]);
}

export async function isFinished(promise) {
	return await Promise.race([
		delay(0, false),
		promise.then(
			() => true,
			() => true
		),
	]);
}
