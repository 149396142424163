import { AxiosResponse } from "axios";

export function longPoll<T>(pollRequest: () => Promise<AxiosResponse<T>>, timeout = 20000, interval = 100): Promise<T> {
    const endTime = Number(new Date()) + timeout;

    const checkCondition = async function (resolve, reject) {
        // If the condition is met, we're done!
        const response = await pollRequest();
        if (response.status === 200) {
            resolve(response.data);
        } else if (response.status === 202 && Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        } else {
            reject(new Error('Exit long polling!'));
        }
    };

    return new Promise(checkCondition);
}