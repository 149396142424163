// currently only used to store and retrieve info of dragged object
// TODO: used for managing no-drop areas

import {
	DragAndDropContext,
	DROP_ZONE_SECTION,
	DRAG_ZONE_SECTION,
	IDragAndDropManager,
	DragAnimation,
	DragAssets,
	CustomizerElement,
	CustomizerUpload,
} from '@pixcap/ui-core/models/widgets/dragAndDropManager.interface';

// TODO: to be added with data-dropzone attribute

class DragImage {
	private _container: HTMLElement;
	private _imgBox: HTMLElement;
	private _img: HTMLImageElement;
	private _width: number;
	private _height: number;
	private _initialWidth: number;
	private _initialHeight: number;
	private _offsetX: number;
	private _offsetY: number;
	private _imgUrl: string;
	private _imageX: number;
	private _imageY: number;
	_handleMoveObject: (e: MouseEvent) => void;

	constructor(payload: { imgUrl: string; width: number; height: number; imageX: number; imageY: number; offsetX?: number; offsetY?: number }) {
		const { imgUrl, width, height, imageX, imageY, offsetY, offsetX } = payload;
		this._createContainer();
		this._imgUrl = imgUrl;
		this._width = width;
		this._height = height;
		this._initialWidth = width;
		this._initialHeight = height;
		this._offsetX = offsetX;
		this._offsetY = offsetY;
		this._imageX = imageX;
		this._imageY = imageY;
		this._createDragImage();
		this._handleMoveObject = this.handleMoveObject.bind(this);
		document.addEventListener('drag', this._handleMoveObject);
	}

	private _createContainer() {
		this._container = document.createElement('div');
		document.body.appendChild(this._container);
		this._container.style.position = 'absolute';
		this._container.style.pointerEvents = 'none';
		this._container.style.width = '100%';
		this._container.style.height = '100%';
	}

	private _createDragImage() {
		this._createImageBox();
		this._img = document.createElement('img');
		this._img.style.width = '100%';
		this._img.style.maxHeight = '100%';
		this._imgBox.style.objectFit = 'contain';
		this._setImageProperties();
		this._imgBox.appendChild(this._img);
	}

	private _createImageBox() {
		this._imgBox = document.createElement('div');
		this._imgBox.style.position = 'absolute';
		this._imgBox.style.pointerEvents = 'none';
		this._imgBox.style.objectFit = 'contain';
		this._imgBox.style.opacity = '0.5';
		this._imgBox.style.transition = 'width .2s ease,height .2s ease';
		this._imgBox.style.display = 'flex';
		this._imgBox.style.justifyContent = 'center';
		this._imgBox.style.alignItems = 'center';
		this._container.appendChild(this._imgBox);
	}

	private _setImageProperties() {
		if (this._img.src != this._imgUrl) this._img.src = this._imgUrl;
		this._imgBox.style.width = `${this._width}px`;
		this._imgBox.style.height = `${this._height}px`;
		this._imgBox.style.left = `${this._imageX - this._offsetX}px`;
		this._imgBox.style.top = `${this._imageY - this._offsetY}px`;
	}

	get dragImageSize() {
		return {
			x: this._imageX - this._offsetX,
			y: this._imageY - this._offsetY,
			w: this._width,
			h: this._height,
		};
	}

	updateDragImage(payload: { imgUrl?: string; width?: number; height?: number }) {
		const { imgUrl, width, height } = payload;
		if (imgUrl) this._imgUrl = imgUrl;
		this._width = width || this._initialWidth;
		this._height = height || this._initialHeight;
		this._setImageProperties();
	}

	handleMoveObject(e: MouseEvent) {
		this._imageX = e.clientX;
		this._imageY = e.clientY;
		this._setImageProperties();
	}

	dispose() {
		this._img.remove();
		this._container.remove();
		document.removeEventListener('drag', this._handleMoveObject);
	}
}

export class DragAndDropManager implements IDragAndDropManager {
	private _dragAndDropContext: DragAndDropContext;
	private _dragImage: DragImage;

	constructor() {
		this._dragAndDropContext = {
			[DROP_ZONE_SECTION.CANVAS]: {},
			[DROP_ZONE_SECTION.CUSTOMIZER_CANVAS]: {},
		};
	}

	getDragImageSize() {
		return this._dragImage?.dragImageSize;
	}

	setDragImage(payload: { imgUrl: string; e: DragEvent }) {
		const { e, imgUrl } = payload;
		const div = document.createElement('div');
		e.dataTransfer.setDragImage(div, 0, 0);
		const targetElem = (e.target as HTMLElement).getBoundingClientRect();
		this._dragImage = new DragImage({
			imgUrl,
			width: targetElem.width,
			height: targetElem.height,
			offsetX: e.clientX - targetElem.left,
			offsetY: e.clientY - targetElem.top,
			imageX: e.clientX,
			imageY: e.clientY,
		});
	}

	deleteDragImage() {
		this._dragImage?.dispose();
		this._dragImage = undefined;
	}

	updateDragImage(payload: { imgUrl?: string; width?: number; height?: number }) {
		if (this._dragImage) this._dragImage.updateDragImage(payload);
	}

	/**
	 * called on dragstart
	 */
	initDrag(sourceDragZone: DRAG_ZONE_SECTION.ANIMATION, targetDropZone: DROP_ZONE_SECTION.CANVAS, dragObject: DragAnimation): void;
	initDrag(sourceDragZone: DRAG_ZONE_SECTION.ASSET_FILE, targetDropZone: DROP_ZONE_SECTION.CANVAS, dragObject: DragAssets): void;
	initDrag(
		sourceDragZone: DRAG_ZONE_SECTION.CUSTOMIZER_ELEMENT,
		targetDropZone: DROP_ZONE_SECTION.CUSTOMIZER_CANVAS,
		dragObject: CustomizerElement
	): void;
	initDrag(
		sourceDragZone: DRAG_ZONE_SECTION.CUSTOMIZER_UPLOADS,
		targetDropZone: DROP_ZONE_SECTION.CUSTOMIZER_CANVAS,
		dragObject: CustomizerUpload
	): void;

	initDrag(sourceDragZone, targetDropZone, dragObject): void {
		this._dragAndDropContext[targetDropZone][sourceDragZone] = dragObject;
	}

	/**
	 * called on dragend
	 */
	finishDrag(sourceDragZone: DRAG_ZONE_SECTION, targetDropZone: DROP_ZONE_SECTION): void {
		this._dragAndDropContext[targetDropZone][sourceDragZone] = undefined;
	}

	/**
	 * retrieve dragObject with targetDropZone
	 */
	getDragObject(sourceDragZone: DRAG_ZONE_SECTION.ANIMATION, targetDropZone: DROP_ZONE_SECTION.CANVAS): DragAnimation | undefined;
	getDragObject(sourceDragZone: DRAG_ZONE_SECTION.ASSET_FILE, targetDropZone: DROP_ZONE_SECTION.CANVAS): DragAssets | undefined;
	getDragObject(
		sourceDragZone: DRAG_ZONE_SECTION.CUSTOMIZER_ELEMENT,
		targetDropZone: DROP_ZONE_SECTION.CUSTOMIZER_CANVAS
	): CustomizerElement | undefined;
	getDragObject(
		sourceDragZone: DRAG_ZONE_SECTION.CUSTOMIZER_UPLOADS,
		targetDropZone: DROP_ZONE_SECTION.CUSTOMIZER_CANVAS
	): CustomizerUpload | undefined;

	getDragObject(sourceDragZone, targetDropZone) {
		return this._dragAndDropContext[targetDropZone][sourceDragZone];
	}
}
