import type { IWindowEventsManager, EventListeners, EventHandler } from '@pixcap/ui-core/models/widgets/windowEventsManager.interface';
import { EventType, EVENTS_MANAGER_COMPONENT } from '@pixcap/ui-core/models/widgets/windowEventsManager.interface';
const EMC_VALUES = {
	[EVENTS_MANAGER_COMPONENT.CANVAS]: EVENTS_MANAGER_COMPONENT.CANVAS,
	[EVENTS_MANAGER_COMPONENT.ASSETS_MANAGER]: EVENTS_MANAGER_COMPONENT.ASSETS_MANAGER,
	[EVENTS_MANAGER_COMPONENT.CURVE_EDITOR]: EVENTS_MANAGER_COMPONENT.CURVE_EDITOR,
	[EVENTS_MANAGER_COMPONENT.INSPECTOR]: EVENTS_MANAGER_COMPONENT.INSPECTOR,
	[EVENTS_MANAGER_COMPONENT.PICKERS]: EVENTS_MANAGER_COMPONENT.PICKERS,
	[EVENTS_MANAGER_COMPONENT.LAYERS]: EVENTS_MANAGER_COMPONENT.LAYERS,
	[EVENTS_MANAGER_COMPONENT.SCENE_TREE]: EVENTS_MANAGER_COMPONENT.SCENE_TREE,
	[EVENTS_MANAGER_COMPONENT.RETARGETING]: EVENTS_MANAGER_COMPONENT.RETARGETING,
	[EVENTS_MANAGER_COMPONENT.IK]: EVENTS_MANAGER_COMPONENT.IK,
	[EVENTS_MANAGER_COMPONENT.ASSETS_ANIMATIONS_TAB]: EVENTS_MANAGER_COMPONENT.ASSETS_ANIMATIONS_TAB,
	[EVENTS_MANAGER_COMPONENT.ASSETS_FILES_TAB]: EVENTS_MANAGER_COMPONENT.ASSETS_FILES_TAB,
};

export class WindowEventsManager implements IWindowEventsManager {
	private _eventListeners: EventListeners;
	private _activeComponent: EVENTS_MANAGER_COMPONENT;

	// store event listener object for deregister
	private _onClickEventListenerObj: EventListenerObject = this._onClick.bind(this);

	constructor() {
		this._eventListeners = {};
		this._activeComponent = EVENTS_MANAGER_COMPONENT.DEFAULT;
		window.addEventListener('click', this._onClickEventListenerObj);
	}

	private _onClick(event: MouseEvent) {
		// check active component
		const closestComponent: null | HTMLElement = (event.target as Element).closest('[data-component]');
		const emc: EVENTS_MANAGER_COMPONENT = closestComponent ? EMC_VALUES[closestComponent.dataset.component] : EVENTS_MANAGER_COMPONENT.DEFAULT;
		this.switchActiveComponent(emc);
	}

	/**
	 * eventHandler: {callback: Function, domain: string, callbackName: string}
	 * @param {*} event
	 * @param {*} eventHandler
	 */
	registerEventListener(event: EventType, eventHandler: EventHandler, options?) {
		// if first time registering event to cache, also register to window
		if (this._eventListeners[event] == null) {
			window.addEventListener(event, (e) => {
				const registeredCallbacks = this._eventListeners[e.type];
				for (const registeredCallback of registeredCallbacks) {
					if (registeredCallback.domain == this._activeComponent || registeredCallback.domain == EVENTS_MANAGER_COMPONENT.DEFAULT) {
						const callback = registeredCallback.callback;
						callback(e);
					}
				}
			}, options);

			this._eventListeners[event] = [];
		}
		this._eventListeners[event].push(eventHandler);
	}

	deregisterEventListener(event: EventType, domain: EVENTS_MANAGER_COMPONENT, callbackName: string) {
		const registeredCallbacks = this._eventListeners[event];
		if (!registeredCallbacks) return;
		const eventHandlerIndex = registeredCallbacks.findIndex((_) => _.domain === domain && _.callbackName === callbackName);
		if (eventHandlerIndex > -1) registeredCallbacks.splice(eventHandlerIndex, 1);
	}

	switchActiveComponent(newActiveComponent: EVENTS_MANAGER_COMPONENT) {
		if (this._activeComponent === newActiveComponent) return;
		this._activeComponent = newActiveComponent;
	}

	getActiveComponent(): EVENTS_MANAGER_COMPONENT {
		return this._activeComponent;
	}

	reset() {
		window.removeEventListener('click', this._onClickEventListenerObj);
	}
}
