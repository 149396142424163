import { UserDeviceTypes } from '@pixcap/ui-core/models/store/app.interface';

export function isMacOS() {
	const currentPlatform = navigator.platform;
	if (currentPlatform?.toLowerCase().indexOf('mac') > -1) return true;
	return false;
}

export function getUserDeviceType(userAgent: string) {
	let agentType = UserDeviceTypes.DESKTOP;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
		agentType = UserDeviceTypes.TABLET;
	}
	if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
		agentType = UserDeviceTypes.MOBILE;
	}
	return agentType;
}
