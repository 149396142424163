import cryptoJs from 'crypto-js';

function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
	return btoa(
		new Uint8Array(arrayBuffer).reduce(function (data, byte) {
			return data + String.fromCharCode(byte);
		}, '')
	);
}

function wordToByteArray(word: number, length: number) {
	const ba: any[] = [];
	const xFF = 0xff;
	if (length > 0) ba.push(word >>> 24);
	if (length > 1) ba.push((word >>> 16) & xFF);
	if (length > 2) ba.push((word >>> 8) & xFF);
	if (length > 3) ba.push(word & xFF);

	return ba;
}

function wordArrayToByteArray({ words, sigBytes }: { sigBytes: number; words: number[] }) {
	const result: any[] = [];
	let bytes;
	let i = 0;
	while (sigBytes > 0) {
		bytes = wordToByteArray(words[i], Math.min(4, sigBytes));
		sigBytes -= bytes.length;
		result.push(bytes);
		i++;
	}
	return new Uint8Array(result.flat());
}

async function decryptContent(encrypted: Blob, encryptKeyBase64: string, encryptIvBase64: string) {
	const ivWordArray = cryptoJs.enc.Base64.parse(encryptIvBase64);
	const decryptedRaw = cryptoJs.AES.decrypt(arrayBufferToBase64(await encrypted.arrayBuffer()), cryptoJs.enc.Base64.parse(encryptKeyBase64), {
		iv: ivWordArray,
		mode: cryptoJs.mode.CTR,
		padding: cryptoJs.pad.NoPadding,
	});
	const decrypted = new Blob([wordArrayToByteArray(decryptedRaw)]);
	return decrypted;
}

// extract encryptKey and encryptIv from presigned url response if any
function extractEncryptionMetadata(presignedUrlResponse: any) {
	let encryptKey;
	let encryptIv;
	if (presignedUrlResponse.meta) {
		encryptKey = presignedUrlResponse.meta['0'];
		encryptIv = presignedUrlResponse.meta['1'];
	}
	return { encryptKey, encryptIv };
}

export { extractEncryptionMetadata, decryptContent };
