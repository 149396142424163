// this services will help us to allow sharing function between components it's same as native provide/inject of vue but not only from parent to child , it's for all parent to child / child to parent , parent to parent , child to child ...
import type {
	InjectionCallback,
	CONTEXT_PROVIDER_TYPES,
	ProviderInjections,
	IContextProvider,
} from '@pixcap/ui-core/models/widgets/contextProvider.interface';

export class ContextProvider implements IContextProvider {
	private _providerInjections: ProviderInjections = {};

	emitProviderInjections(providerType: CONTEXT_PROVIDER_TYPES, ...args) {
		const injections = this._providerInjections[providerType] || [];

		injections.forEach((injection) => {
			injection.callback(...args);
		});
	}

	resetProviderInjections(providerType: CONTEXT_PROVIDER_TYPES) {
		this._providerInjections[providerType] = [];
	}

	registerProviderInjection(providerType: CONTEXT_PROVIDER_TYPES, callback: InjectionCallback) {
		if (!this._providerInjections[providerType]) this._providerInjections[providerType] = [];
		this._providerInjections[providerType].push(callback);
	}

	deregisterProviderInjection(providerType: CONTEXT_PROVIDER_TYPES, callbackName: string) {
		if (!this._providerInjections[providerType]) return;
		this._providerInjections[providerType] = this._providerInjections[providerType].filter((injection) => injection.callbackName != callbackName);
	}
}
