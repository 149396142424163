import { REF_ORIGIN_SOURCE, UTM_ORIGIN_SOURCE } from '@pixcap/ui-core/constants/shared.constants';

export function openPopUpWindow(url, width, height) {
	const LeftPosition = window.innerWidth ? (window.innerWidth - width) / 2 : 0;
	const TopPosition = window.innerHeight ? (window.innerHeight - height) / 2 : 0;
	window.open(
		url,
		'popUpWindow',
		`height=${height},width=${width},top=${TopPosition},left=${LeftPosition},resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=yes,status=yes`
	);
}

export function downloadDirectFromUrl(url: any, name: string, shouldRevokeUrl = true) {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	if (shouldRevokeUrl) window.URL.revokeObjectURL(url);
	document.body.removeChild(a);
}

export async function downloadDirectFromPresignedUrl(url: any, name: string) {
	const blob = await fetch(url, { cache: 'no-cache' }).then((res) => res.blob());
	const a = document.createElement('a');
	a.addEventListener('click', function (e) {
		e.cancelBubble = true;
	});
	a.href = URL.createObjectURL(blob);
	a.download = name;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export function getIsMobileView() {
	const windowWidth = window.innerWidth;
	const isMobileView = windowWidth <= 950;
	return isMobileView;
}

export function getCookie(cookie, name) {
	const value = '; ' + cookie;
	const parts = value.split('; ' + name + '=');
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	return null;
}

export function storeOriginSources() {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const arrayParams = Array.from(urlSearchParams.entries());
	const params = {};
	for (let arrayParam of arrayParams) {
		const [key, value] = arrayParam;
		if (params[key] == null) params[key] = [];
		params[key].push(value);
	}

	if (params['ref'] != undefined) {
		const refValue = Array.isArray(params['ref']) ? params['ref'][0] : params['ref'];
		localStorage.setItem(REF_ORIGIN_SOURCE, refValue);
	}
	if (params['utm_source'] != undefined) {
		const refValue = Array.isArray(params['utm_source']) ? params['utm_source'][0] : params['utm_source'];
		localStorage.setItem(UTM_ORIGIN_SOURCE, refValue);
	}
}

export function cloneEvent(e, override) {
	const newEvent = {};
	for (const key in e) {
		newEvent[key] = override[key] || e[key];
	}
	return newEvent;
}

export function copyTextToClipboard(textToCopy: string) {
	const cb = navigator.clipboard;
	if (!cb) return null;
	return cb.writeText(textToCopy);
}

export function getTextFromClipboard() {
	const cb = navigator.clipboard;
	if (!cb) return null;
	return cb.readText();
}

export function isExcludedTarget(target: HTMLElement) {
	return !!(target && (['input', 'textarea'].includes(target.tagName.toLowerCase()) || target.getAttribute('contenteditable')));
}
